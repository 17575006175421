.layout_main___JsHm{grid-area:main;width:100%;background-color:#e9e9e9}.layout_mainContent__eeUOT{margin:0 auto;width:100%;max-width:1320px}.layout_logo__OUnqq{display:flex;flex-direction:row;padding-left:20px}.layout_logoText__hdJ9_{display:flex;height:32px;align-items:center;justify-content:center;margin-left:10px;font-size:20px}.layout_footer__VoTAq{grid-area:footer;background-color:#511c29;height:30px}.layout_header__jI8dY{box-shadow:0 3px 2px -2px gray;z-index:2}.layout_headerLeft__ju0BE{display:flex;align-items:center;height:48px}.layout_headerMenu__DX8u7{grid-area:header-menu;height:48px;display:flex;align-items:center;justify-content:space-around}.layout_headerRight__DdTP2{grid-area:header-right;display:flex;align-items:center;justify-content:flex-end;height:48px;padding-right:20px}.layout_box__Kd2Gn{color:#fff;font-size:80%;text-align:center}
button.score-ticker_navigateRound__yVQ7u:hover{background-color:orange}.score-ticker_round__WjHhP{display:flex;flex-direction:column;justify-content:center;padding-right:10px;padding-left:10px;font-size:.7rem;text-transform:uppercase;font-weight:bold;color:#f0f0f0;background-color:#1c272c;max-width:125px;min-width:125px}.score-ticker_competition__tJD6V{margin-bottom:5px}.score-ticker_matchCard__qYWFx:hover{background-color:#f2f2f2;cursor:pointer}.score-ticker_matchStatus__ocTh_{text-transform:uppercase;font-size:.6rem;font-weight:bold;margin-bottom:1px}.score-ticker_statusBreak__9GTo5,.score-ticker_statusLive__mCOIN{display:inline-block;color:green;animation:score-ticker_liveUnderline__2QjYl 2s 2s infinite;text-decoration:none;background-image:linear-gradient(transparent 2px, #2ed392 2px, #2ed392 4px, transparent 4px),linear-gradient(transparent 2px, transparent 2px, transparent 4px, transparent 4px);background-size:0% 6px,100% 6px;background-position:bottom;background-repeat:no-repeat;padding-bottom:4px;border-bottom:6px solid rgba(0,0,0,0);margin-bottom:-9px;margin-top:0px}@keyframes score-ticker_liveUnderline__2QjYl{from{background-position:bottom 0 left 0;background-size:0% 6px}50%{background-size:100% 6px}to{background-position:bottom 0 left 0;background-size:0% 6px}}.score-ticker_matchVenue__0lOC8{font-size:.7rem;color:#a2a2a2;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}.score-ticker_matchTeamScorelines__AjbkE{margin-top:5px}.score-ticker_matchTeamScorelines__AjbkE .score-ticker_winner__lM17m{font-weight:bold}.score-ticker_matchTeamScoreline__IiegI{display:flex;flex-direction:row;align-items:center;justify-content:space-between;color:#4a4a4a}.score-ticker_matchTeamName__xFFuq{flex:.55;margin-left:5px}.score-ticker_matchTeamScore__9crEB{flex:1;display:flex;flex-direction:row}.score-ticker_matchTeamScoreGB___B71k{align-self:center;flex:.5;text-align:right}.score-ticker_matchTeamScoreTotal__2cPEf{align-self:center;flex:.5;text-align:right}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_c9cbed';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/ffff0c425fbeefe6-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_c9cbed';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/c21b67b0a36892e5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_c9cbed';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/c5a3bf8cfa32037a-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_c9cbed';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/b2f7755ffc613443-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_c9cbed';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/369c6e283c5acc6e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_c9cbed';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/92f44bb82993d879-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Mono_Fallback_c9cbed';src: local("Arial");ascent-override: 77.08%;descent-override: 19.93%;line-gap-override: 0.00%;size-adjust: 135.95%
}.__className_c9cbed {font-family: '__Roboto_Mono_c9cbed', '__Roboto_Mono_Fallback_c9cbed';font-style: normal
}.__variable_c9cbed {--font-roboto-mono: '__Roboto_Mono_c9cbed', '__Roboto_Mono_Fallback_c9cbed'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_aaf875';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_aaf875 {font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875';font-style: normal
}.__variable_aaf875 {--font-inter: '__Inter_aaf875', '__Inter_Fallback_aaf875'
}

