button.navigateRound:hover {
  background-color: orange;
}

.round {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #f0f0f0;
  background-color: #1c272c;
  max-width: 125px;
  min-width: 125px;
}

.competition {
  margin-bottom: 5px;
}

.matchCard:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.matchStatus {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: bold;
  margin-bottom: 1px;
}

.statusBreak, .statusLive {
  display: inline-block;
  color: green;
  animation: liveUnderline 2s 2s infinite;
  text-decoration: none;
  background-image:
          linear-gradient(
                          transparent 2px,
                          #2ed392 2px,
                          #2ed392 4px,
                          transparent 4px
          ),
          linear-gradient(
                          transparent 2px,
                          transparent 2px,
                          transparent 4px,
                          transparent 4px
          );
  background-size: 0% 6px, 100% 6px;
  // transition: background-size 0.3s ease-in-out;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 4px;
  border-bottom: 6px solid transparent;
  margin-bottom: -9px;
  margin-top: 0px;
}

@keyframes liveUnderline {
  from {
    background-position: bottom 0 left 0;
    background-size: 0% 6px;
  }
  50% { background-size: 100% 6px; }
  to {
    background-position: bottom 0 left 0;
    background-size: 0% 6px;
  }
}

.matchVenue {
  font-size: 0.7rem;
  color: #a2a2a2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.matchTeamScorelines {
  margin-top: 5px;
  .winner {
    font-weight: bold
  }
}

.matchTeamScoreline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #4a4a4a;
}

.matchTeamName {
  flex: 0.55;
  margin-left: 5px;
}

.matchTeamScore {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.matchTeamScoreGB {
  align-self: center;
  flex: 0.5;
  text-align: right;
}

.matchTeamScoreTotal {
  align-self: center;
  flex: 0.5;
  text-align: right;
}