.main {
    grid-area: main;
    width: 100%;
    background-color: #e9e9e9;
}

.mainContent {
    margin: 0 auto;
    width: 100%;
    max-width: 1320px;
}

.content {
}

.logo {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

.logoText {
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-size: 20px;
}

.footer {
    grid-area: footer;
    background-color: #511C29;
    height: 30px;
}

$header-height: 48px;

.header {
    box-shadow: 0 3px 2px -2px gray;
    z-index: 2;
}

.headerLeft {
    display: flex;
    align-items: center;
    height: $header-height;
}

.headerMenu {
    grid-area: header-menu;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.headerRight {
    grid-area: header-right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $header-height;
    padding-right: 20px;
}

.box {
    color: #fff;
    font-size: 80%;
    text-align: center;
}